<template>
  <div class="container">
    <div class="periphery">
      <span>标题：</span>
      <div style="display: inline-block; width: 25%;">
        <el-input
          placeholder="请输入内容"
          v-model="input2"
          size="small"
          clearable
          @keyup.enter.native="search"
          style="width: 80%"
        >
          <template slot="append">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </template>
        </el-input>
        <el-button
          type="warning"
          size="small"
          style="margin-left: 10px"
          @click="reset"
          icon="el-icon-refresh"
          >重置
        </el-button>
      </div>
    </div>

    <div class="periphery" style="margin-top: 15px">
      <div style="margin-top: 15px">
        <el-table stripe :data="list">
          <el-table-column
            label="回复人"
            prop="nickName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="回复内容" prop="content"></el-table-column>
          <el-table-column label="状态" prop="state">
            <template slot-scope="scope">
              <el-tag effect="dark" :type="status(scope.row.state).type">
                {{ status(scope.row.state).text }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="发布时间" prop="pubTime"></el-table-column>
          <el-table-column label="回帖类型" prop="type">
            <template slot-scope="scope">
              <el-tag effect="dark" :type="replyType(scope.row.type).type">
                {{ replyType(scope.row.type).text }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-view"
                @click="getEdit(scope.row.id)"
                type="primary"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      class="pagination"
      background
      :total="total"
      :current-page="params.pageNum"
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size="params.pageSize"
      :page-sizes="[10, 20, 50, 100]"
    ></el-pagination>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        size="mini"
        :rules="ruleForm"
        label-width="100px"
      >
        <el-form-item label="公告回复内容" prop="title">
          <el-input
            v-model="form.title"
            clearable
            type="textarea"
            :rows="5"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="发帖人" prop="">
          <el-input
            v-model="form.jordansw"
            clearable
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="评论状态" prop="">
          <el-select
            v-model="form.commentStatus"
            placeholder="请选择评论状态"
            clearable
            :disabled="true"
          >
            <el-option label="正常" value="1"></el-option>
            <el-option label="删除" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评论类型" prop="">
          <el-select
            v-model="form.commentType"
            placeholder="请选择评论类型"
            clearable
            :disabled="true"
          >
            <el-option label="点赞" value="0"></el-option>
            <el-option label="评论" value="1"></el-option>
            <el-option label="其他" value=""></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评论时间" prop="">
          <el-date-picker
            v-model="form.date"
            :disabled="true"
            type="datetime"
            value-format="yyyy-mm-dd HH:mm:ss"
            format="yyyy-mm-dd HH:mm:ss"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <div style="text-align: right;">
            <el-button type="primary" size="small" @click="submitForm('form')"
              >屏蔽回复</el-button
            >
            <el-button size="small" @click="resetForm('form')">关 闭</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { messageBox } from "@/util/index";
export default {
  name: "Details",
  data() {
    return {
      input2: "",
      list: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        title: "",
        actionId: ""
      },
      total: 0,
      title: "查看评论详情",
      dialogVisible: false,
      form: {
        title: "",
        jordansw: "",
        commentStatus: "",
        commentType: "",
        date: ""
      },
      ruleForm: {},
      id: null
    };
  },
  mounted() {
    this.params.actionId = this.$route.params.id;
    this.getData(this.params);
  },
  methods: {
    /**
     * 获取数据
     * @param data
     */
    getData(data) {
      this.postForm(this.config.apiUrl.replyList, data).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.list = res.rows;
          this.total = res.total;
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * 条件搜索
     */
    search() {
      this.params.title = this.input2;
      this.getData(this.params);
    },
    /**
     * 重置
     */
    reset() {
      this.input2 = "";
    },
    /**
     * pageSize 每页条数
     * @param val
     */
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    /**
     * 当前页
     * @param val
     */
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getData(this.params);
    },
    /**
     * 点击图标关闭弹框
     */
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * 提交
     * @param formName
     */
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            id: this.id
          };
          console.log(data);
          messageBox(
            "此操作将永久屏蔽该条信息, 是否继续?",
            "提示",
            this.replyHide,
            data
          );
        } else {
          return false;
        }
      });
    },
    /**
     * 关闭
     * @param formName
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    /**
     * 状态
     * @param status
     * @returns {{}}
     */
    status(status) {
      let obj = {};
      if (status == 1) {
        obj.type = "success";
        obj.text = "正 常";
      } else {
        obj.type = "danger";
        obj.text = "删 除";
      }
      return obj;
    },
    /**
     * 回复装填
     * @param status
     * @returns {{}}
     */
    replyType(status) {
      let obj = {};
      if (status == 1) {
        obj.type = "";
        obj.text = "评 论";
      } else if (status == 0) {
        obj.type = "success";
        obj.text = "点 赞";
      } else {
        obj.type = "danger";
        obj.text = "其 他";
      }
      return obj;
    },
    /**
     * 获取回复详情
     * @param id
     */
    getEdit(id) {
      this.id = id;
      this.get(`${this.config.apiUrl.replyParticulars}${id}`).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dialogVisible = true;

          this.form.title = res.data.content;
          this.form.jordansw = res.data.nickName;
          this.form.commentStatus = res.data.state;
          this.form.commentType = res.data.type;
          this.form.date = res.data.createTime;
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * 对帖子进行屏蔽
     * @param data
     */
    replyHide(data) {
      this.postForm(this.config.apiUrl.replyHide, data).then(res => {
        if (res.code == 0) {
          this.msgSuccess(res.msg);
          this.dialogVisible = false;
          this.getData(this.params);
        } else {
          this.msgError(res.msg);
        }
      });
    }
  }
};
</script>

<style scoped></style>
